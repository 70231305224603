/*
 * @Author: LiZhiWei
 * @Date: 2024-07-24 08:33:26
 * @LastEditors: LiZhiWei
 * @LastEditTime: 2024-07-26 10:56:03
 * @Descripttion:
 */
import { http } from '~/utils/fetch/index'
import type { BasicResponse, TableData } from '~/models/common'
import { EnterpriseCredit, type ISearchCreditParams } from '~/models/credit'
export function getEnterpriseCreditList(params: ISearchCreditParams) {
  return http.request<BasicResponse<TableData<EnterpriseCredit>>>({
    url: '/enterpriseCredit/open',
    method: 'get',
    params,
  })
}

export function getEnterpriseCreditDetail(id: string) {
  return http.request<BasicResponse<EnterpriseCredit>>({
    url: `/enterpriseCredit/open/${id}`,
    method: 'get',
  })
}

export function getEnterpriseCreditListHome(params: ISearchCreditParams) {
  return http.request<BasicResponse<TableData<EnterpriseCredit>>>({
    url: '/enterpriseCredit/open/home',
    method: 'get',
    params,
  })
}
